import { media, styled } from '@styles';
import { pageTitle } from '@styles/utils';
import { Container as DefaultContainer } from '@components/templates/Container';

export const Wrapper = styled.div``;

export const Container = styled(DefaultContainer)`
  padding: 2rem 2rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.smallScreen`
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  `}
`;

export const Title = styled.h2`
  ${pageTitle}
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

export const IconWrapper = styled.div`
  width: 148px;
  height: 148px;
  margin: 0 auto;
  margin-bottom: 40px;
  position: relative;
  ${media.largeScreen`
    width: 126px;
    height: 126px;
    margin-bottom: 20px;
  `}
  img {
    position: absolute;
    left: 0;
    top: 0;
  }
  svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

export const IconShade = styled.div`
  img {
    opacity: 0.3;
    transition: opacity 0.3s;
  }
  * {
    transition: fill 0.4s;
  }
`;

export const IconHover = styled.div`
  img {
    transform: scale(0) !important;
    opacity: 0;
    transition: opacity 0.3s, transform 0.4s;
    transform-origin: center;
  }
  svg {
    transform: scale(0) !important;
    opacity: 0;
    transition: opacity 0.3s, transform 0.4s;
    transform-origin: center;
  }
`;

export const Item = styled.li`
  flex: 0 0 25%;
  padding: 45px 35px;
  position: relative;
  z-index: 1;
  ${media.largeScreen`
    padding: 35px 30px;
  `}
  ${media.mediumScreen`
    flex: 0 0 50%;
  `}
  ${media.smallScreen`
    flex: 0 0 100%;
    padding: 25px;
    &:before {
      content: none !important;
    }
  `}
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 0;
    transition: height 0.4s;
    background-color: ${(props) => props.theme.colors.light};
    z-index: -1;
  }
  &:hover,
  &:active,
  &:focus {
    &:before {
      height: 100%;
    }
    .benefits {
      &__icon {
        &-hover {
          img {
            transform: scale(1) !important;
            opacity: 1;
          }
          svg {
            transform: scale(1) !important;
            opacity: 1;
          }
        }
        &-shade {
          img {
            opacity: 0.6;
          }
          * {
            fill: rgba($red, 0.6);
          }
        }
      }
      &__item {
        &-hint {
          opacity: 0;
        }
        &-title {
          transform: translateY(-10px);
          ${media.smallScreen`
            transform: none;
          `}
        }
        &-description {
          opacity: 0.8;
          transform: translateY(-10px);
        }
        &-more {
          opacity: 0;
          transform: translateY(10px);
        }
      }
    }
  }
`;

export const ItemTitle = styled.div`
  line-height: 1.2em;
  margin-bottom: 16px;
  transition: 0.3s;
  font-family: ${(props) => props.theme.typography.thinFontFamily};
  font-size: ${(props) => props.theme.typography.h5FontSize};

  ${media.largeScreen`
    margin-bottom: 12px;
  `}
  ${media.smallScreen`
    display: flex;
    align-items: center;
    justify-content: center;
  `}
  * {
    font: inherit;
    line-height: inherit;
  }
`;

export const ItemCount = styled.div`
  color: ${(props) => props.theme.colors.gold};
  font-size: ${(props) => props.theme.typography.baseFontSize};
  line-height: 1.3em;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 20px;
  &.mobile-only {
    display: none;
  }
  ${media.smallScreen`
    display: none;
    margin-bottom: 0;
    margin-right: 10px;
    &.mobile-only {
      display: block;
    }
  `}
`;

export const ItemContent = styled.div`
  position: relative;
  ${media.smallScreen`
    display: none;
  `}
`;

export const ItemMore = styled.div`
  color: ${(props) => props.theme.colors.red};
  font-size: ${(props) => props.theme.typography.smallFontSize};
  font-family: ${(props) => props.theme.typography.boldFontFamily};
  line-height: 1.3em;
  letter-spacing: 2px;
  text-transform: uppercase;
  position: absolute;
  left: 30px;
  bottom: 35px;
  transition: 0.3s;
  ${media.smallScreen`
    display: none;
  `}
`;

export const ItemHint = styled.div`
  line-height: 1.6em;
  font-size: ${(props) => props.theme.typography.smallFontSize};
  font-family: ${(props) => props.theme.typography.extraFontFamily};
  position: absolute;
  opacity: 0.8;
  top: 0;
  left: 0;
  transition: 0.2s;
  ${media.smallScreen`
    display: none;
  `}
  * {
    font: inherit;
    font-size: inherit;
    line-height: inherit;
  }
`;

export const ItemDescription = styled.div`
  line-height: 1.6em;
  font-size: ${(props) => props.theme.typography.smallFontSize};
  font-family: ${(props) => props.theme.typography.extraFontFamily};
  opacity: 0;
  transition: 0.4s;
  ${media.smallScreen`
    display: none;
  `}
  * {
    font: inherit;
    font-size: inherit;
    line-height: inherit;
  }
`;

export const Button = styled.div``;
