import { ReactElement } from 'react';

import { ErrorMessage } from '@components/atoms/ErrorMessage';

import { PrismicHomePageQuery } from '@prismic/gqlTypes/prismicOperations';

import { HomePageHero } from '@components/organisms/HomePageHero';
import { SliceZone } from '@prismicio/react';
import { components } from './Slices';

import * as S from './styles';

export interface IProps {
  homepage: PrismicHomePageQuery['homepage'];
}

export function PrismicHomePage({ homepage }: IProps): ReactElement {
  if (!homepage) {
    return (
      <S.HomePage>
        <div className="prismic-home-page">
          <ErrorMessage errors={[{ message: 'oops' }]} />;
        </div>
      </S.HomePage>
    );
  }

  const { hero, body } = homepage || {};

  return (
    <S.HomePage>
      {hero && <HomePageHero hero={hero} />}
      <div>
        <SliceZone slices={body} components={components} />
      </div>
    </S.HomePage>
  );
}
