// import { GetStaticPaths, GetStaticProps } from 'next';

import { GetShopDocument } from '@sdk/gqlTypes/generatedHooks';

import {
  GetShopQuery,
  GetShopQueryVariables,
} from '@sdk/gqlTypes/generatedOperations';

import { PrismicHomePageQueryDocument } from '@prismic/gqlTypes/prismicHooks';

import {
  PrismicHomePageQuery,
  PrismicHomePageQueryVariables,
} from '@prismic/gqlTypes/prismicOperations';

import { incrementalStaticRegenerationRevalidate } from '@temp/constants';

import { prismicApolloClient, saleorApolloClient } from '@utils/ssg';
import { localeToPrismicQuery } from '@utils/regions';

import { generatePrismicSeo } from '@utils/seo';
import { paths } from '@temp/paths';

import { HomePage } from '@temp/views/Home';

export default HomePage;

export const getStaticProps = async ({ locale, defaultLocale }) => {
  const { data: dataHomepage } = await prismicApolloClient.query<
    PrismicHomePageQuery,
    PrismicHomePageQueryVariables
  >({
    query: PrismicHomePageQueryDocument,
    variables: {
      ...localeToPrismicQuery(locale),
    },
  });

  const { data: dataShop } = await saleorApolloClient.query<
    GetShopQuery,
    GetShopQueryVariables
  >({
    query: GetShopDocument,
  });

  const seo = await generatePrismicSeo(
    dataHomepage.homepage,
    paths.home,
    'website',
    locale,
    defaultLocale
  );

  return {
    revalidate: incrementalStaticRegenerationRevalidate,
    props: {
      shop: dataShop.shop,
      homepage: dataHomepage.homepage,
      seo,
    },
  };
};
