import { media, styled } from '@styles';

export const Wrapper = styled.div`
  padding 2em 0;
  border-top: 1px solid #b9c5cb;
  max-width: 100vw;
  margin-left: 60px;
  margin-right: 60px;
  color: ${(props) => `rgba(${props.theme.colors.baseFont}, 0.8)`};
  line-height: 1.5em;
  text-align: center;
  font-family: ${(props) => props.theme.typography.extraFontFamily};
  font-size: ${(props) => props.theme.typography.baseFontSize};
  ${media.mediumScreen`
    margin-left: 0;
    margin-right: 0;
  `}
  ${media.smallScreen`
    border: none;
  `}
  * {
    line-height: 1.5em;
  }
`;

export const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex: 0 0 20%;
  width: 77.7vw;
  max-width: 1491px;
  margin: 0 auto;
  ${media.mediumScreen`
    width: 100%;
    flex-wrap: wrap;
  `}
`;

export const Item = styled.li`
  ${media.smallScreen`
    order: 2;
    width: 16%;
  `}
  img {
    display: block;
    margin: 0 auto;
    width: auto;
    height: auto;
    max-width: 90px;
    max-height: 40px;
    ${media.smallScreen`
      max-width: 100%;
    `}
  }
  &-rate {
    ${media.smallScreen`
      order: 0;
      flex: 0 0 100%;
      display: flex;
      padding-top: 20px;
      position: relative;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;
    `}
    p {
      ${media.smallScreen`
        margin: 0 7px;
      `}
    }
    img {
      margin-bottom: 13px;
      ${media.smallScreen`
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      `}
    }
  }
`;
