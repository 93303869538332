import { media, styled } from '@styles';
import { keyframes } from 'styled-components';

import titleTopImage from 'images/title-top.svg';
import titleBottomImage from 'images/title-bottom.svg';

const ArrowOpen = keyframes`
  0% {
    top: 0;
    left: -40px;
  }
  35% {
    top: 175px;
    left: -40px;
  }
  100% {
    top: 175px;
    left: 430px;
  }
`;

const ArrowOpenLarge = keyframes`
  0% {
    top: -5px;
    left: -19px;
  }
  35% {
    top: 140px;
    left: -19px;
  }
  100% {
    top: 140px;
    left: 310px;
  }
`;

const ArrowOpenMedium = keyframes`
  0% {
    top: -5px;
    left: -19px;
  }
  35% {
    top: 150px;
    left: -19px;
  }
  100% {
    top: 150px;
    left: 250px;
  }
`;

const ArrowOpenSmall = keyframes`
  0% {
    top: -10px;
    left: -12px;
  }
  35% {
    top: 50px;
    left: -12px;
  }
  100% {
    top: 50px;
    left: 300px;
  }
`;

const ContentOpen = keyframes`
  from {
    height: 0;
  }
  to {
    height: 140px;
  }
`;

const ContentOpenLarge = keyframes`
  from {
    height: 0;
  }
  to {
    height: 120px;
  }
`;

const ContentOpenMedium = keyframes`
  from {
    height: 0;
  }
  to {
    height: 100px;
  }
`;

const ContentOpenSmall = keyframes`
  from {
    height: 0;
  }
  to {
    height: 40px;
  }
`;

export const Content = styled.div`
  box-sizing: content-box;
  max-width: 490px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  margin-left: 5%;
  position: absolute;
  ${media.largeScreen`
    max-width: 350px;
  `}
  ${media.smallScreen`
    text-align: center;
    max-width: 400px;
    padding: 0;
    height: 100%;
    justify-content: space-between;
    min-height: 270px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
  `}
`;

export const Wrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.redLight};
  display: flex !important;
  align-items: center;
  ${media.smallScreen`
    justify-content: center;
  `}
`;

export const Image = styled.div`
  overflow: hidden;
  width: 100%;
  height: 50vw;
  &:before {
    content: '';
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      90deg,
      #f5f1f0 80%,
      rgba(255, 255, 255, 0) 100%
    );
    opacity: 0.9;
    height: 100%;
    width: 40%;
  }
  ${media.smallScreen`
    height: 100%;
    margin-top: 90px;
    &:before {
      background: linear-gradient(
        180deg,
        #f5f1f0 80%,
        rgba(255, 255, 255, 0) 100%
      );
      height:25%;
      width:100%;
    }
  `}
  img {
    max-height: 100%;
    width: 100%;
    object-fit: cover;
    ${media.smallScreen`
      max-width: 100%;
      height: 100%;
    `}
  }
`;

export const Title = styled.div`
  // height: 190px;
  margin-bottom: 60px;
  ${media.largeScreen`
    margin-bottom: 44px;
    // height: 144px;
  `}
  ${media.mediumScreen`
    margin-bottom: 30px;
    // height: 137px;
  `}
  ${media.smallScreen`
    margin-top: 1em;
    margin-bottom: 10px;
    // height: 75px;
  `}
`;

export const TitleInner = styled.div`
  position: relative;
  padding: 40px 0 22px;
  // height: 190px;
  ${media.largeScreen`
    padding: 30px 0 22px;
    // height: 144px;
  `}
  ${media.mediumScreen`
    padding: 30px 0 15px;
    // height: 137px;
  `}
  ${media.smallScreen`
    padding: 15px 0 10px;
    // height: 85px;
    width: 320px;
    margin: 0 auto;
  `}
`;

export const TitleContent = styled.div`
  width: 500px;
  height: 0;
  overflow: hidden;
  animation-fill-mode: both;
  font-family: ${(props) => props.theme.typography.thinFontFamily};
  font-size: ${(props) => props.theme.typography.h2FontSize};
  line-height: ${(props) => props.theme.typography.h2FontSize};
  h1,
  h2,
  h3 {
    font-size: inherit;
    line-height: inherit;
    ${media.smallScreen`
      font-size: ${(props) => props.theme.typography.h4FontSize};
      line-height: ${(props) => props.theme.typography.h4FontSize};
    `}
  }
  & em {
    color: ${(props) => props.theme.colors.red};
  }
  animation-name: ${ContentOpen};
  animation-duration: 0.3s;
  animation-delay: 1.1s;
  opacity: 1;
  transition: opacity;
  transition-delay: 0.7s;
  ${media.largeScreen`
    width: 365px;
    animation-name: ${ContentOpenLarge};
  `}
  ${media.mediumScreen`
    width: 280px;
    animation-name: ${ContentOpenMedium};
  `}
  ${media.smallScreen`
    width: 320px;
    animation-name: ${ContentOpenSmall};
  `}
`;

export const TitleBefore = styled.div`
  position: absolute;
  left: -44px;
  top: 0;
  display: inline-block;
  width: 92px;
  height: 15px;
  background: url(${titleTopImage}) no-repeat left top;
  background-size: contain;
  ${media.largeScreen`
    left: -25px;
    width: 64px;
    height: 15px;
  `}
  ${media.smallScreen`
    left: -15px;
    width: 35px;
    height: 15px;
  `}
`;

export const TitleAfter = styled.div`
  position: absolute;
  display: inline-block;
  width: 92px;
  height: 15px;
  background: url(${titleBottomImage}) no-repeat right bottom;
  background-size: contain;
  animation-fill-mode: both;
  top: 0;
  left: -40px;
  ${media.largeScreen`
    width: 64px;
    height: 15px;
    left: -19px;
    top: -5px;
    animation-name: ${ArrowOpenLarge};
  `}
  ${media.mediumScreen`
    animation-name: ${ArrowOpenMedium};
  `}
  ${media.smallScreen`
    width: 35px;
    height: 15px;
    left: -12px;
    top: -10px;
    animation-name: ${ArrowOpenSmall};
  `}

  animation-name: ${ArrowOpen};
  animation-duration: 1s;
  animation-delay: 1s;
  opacity: 1;
  transition: opacity;
  transition-delay: 1s;
`;

export const Text = styled.div`
  max-width: 460px;
  line-height: 1.6em;
  margin-bottom: 60px;
  font-family: ${(props) => props.theme.typography.extraFontFamily};
  font-size: ${(props) => props.theme.typography.baseFontSize};
  * {
    font: inherit;
    color: inherit;
    line-height: inherit;
  }
  ${media.largeScreen`
    margin-bottom: 40px;
  `}
  ${media.mediumScreen`
    margin-bottom: 30px;
  `}
  ${media.smallScreen`
    margin-bottom: 10px;
  `}
`;

export const Button = styled.a`
  button {
    width: 320px;
    padding-left: 0;
    padding-right: 0;
    ${media.largeScreen`
      width: 245px;
    `}
    ${media.mediumScreen`
      width: 220px;
    `}
    ${media.smallScreen`
      margin-bottom: 2em;
    `}
  }
`;
