import { PrismicRichText } from '@prismicio/react';
import { clsx } from 'clsx';
import { ReactElement } from 'react';
import { PrismicNextImage } from '@prismic/components/PrismicNextImage';

import { IProps } from './types';
import * as S from './styles';

export function SliceTrusted({ slice: { fields } }: IProps): ReactElement {
  return (
    <S.Wrapper className="trusted">
      {fields && (
        <S.List className="trusted__list">
          {fields.map((item, i) => (
            <S.Item
              key={i}
              className={clsx('trusted__item', {
                'trusted__item-rate': item.text,
              })}
            >
              <PrismicNextImage field={item.image} />
              {item.text && <PrismicRichText field={item.text} />}
            </S.Item>
          ))}
        </S.List>
      )}
    </S.Wrapper>
  );
}

SliceTrusted.componentName = 'trusted';
