import { media, styled } from '@styles';
import { css } from 'styled-components';
import { pageTitle } from '@styles/utils';

import { Container as DefaultContainer } from '@components/templates/Container';

import chevronLeftImage from 'images/chevron_left.svg';
import chevronRightImage from 'images/chevron_right.svg';

const slickArrow = css`
  .slick-arrow {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
    z-index: 1;
    transform: translate3d(0, 0, 0);

    ${media.smallScreen`
      display: block !important;
      width: 40px;
      height: 40px;
    `}
  }
`;

const slickPrev = css`
  .slick-prev {
    background: url(${chevronLeftImage}) no-repeat center !important;
  }
`;

const slickNext = css`
  .slick-next {
    background: url(${chevronRightImage}) no-repeat center !important;
  }
`;

const slickTrack = css`
  .slick-track {
    display: flex !important;
    ${media.smallScreen`
      overflow: visible;
    `}
  }
`;

const slickSlide = css`
  .slick-slide {
    height: auto;

    & > div {
      height: 100%;

      & > div {
        height: 100%;
      }
    }
  }
`;

const slickList = css`
  .slick-list {
    margin: 0px -20px;

    ${media.largeScreen`
      margin: 0px -12px;
    `}

    ${media.mediumScreen`
    `}

    ${media.smallScreen`
      margin: 0px -4px;
      overflow: visible;
    `}
  }
`;

const slickDisabled = css`
  .slick-disabled {
    opacity: 0;
  }
`;

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.light};
`;

export const Container = styled(DefaultContainer)`
  padding: 2rem 2rem;

  ${media.smallScreen`
    overflow: hidden;
  `}

  ${slickList}
  ${slickTrack}
  ${slickSlide}
  ${slickArrow}
  ${slickPrev}
  ${slickNext}
  ${slickDisabled}
`;

export const Header = styled.div`
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.smallScreen`
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  `}
`;

export const Title = styled.h2`
  ${pageTitle}
`;

export const Link = styled.a`
  font-family: ${(props) => props.theme.typography.thinFontFamily};
  font-size: ${(props) => props.theme.typography.baseFontSize};
  font-style: normal;
  font-weight: normal;
  line-height: 100%;
  color: ${(props) => props.theme.colors.baseFont};
  ${media.largeScreen`
    letter-spacing: -0.5px;
  `}

  &:after {
    content: '';
    display: block;
    height: 2px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.red};
  }
`;

export const Slide = styled.div`
  display: block !important;
  height: 100%;
  padding: 1em;
`;
