import { GetShopQuery } from '@sdk/gqlTypes/generatedOperations';

export const structuredData = (shop: GetShopQuery['shop']): string =>
  JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    description: shop ? shop.description : '',
    name: shop ? shop.name : '',
    url: process.env.NEXT_PUBLIC_VERCEL_URL || 'https://www.renodoors.eu/',
  });
