import { media, styled } from '@styles';
import { spacer } from '@styles/constants';

export const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: ${`${2 * spacer}rem ${spacer}rem`};
`;

export const Header = styled.h2`
  font-size: ${({ theme: { typography } }) => typography.ultraBigFont};
  font-weight: 900;
  line-height: 100%;
  color: ${(props) => props.theme.colors.primary};
  padding-bottom: 20px;
`;

export const Ruler = styled.div`
  background: ${(props) => props.theme.colors.primary};
  min-width: ${`${10 * spacer}rem`};
  height: 2px;
  margin-top: ${`${spacer}rem`};
  margin-bottom: ${`${3 * spacer}rem`};
`;

export const Message = styled.div`
  ${media.smallScreen`
    p {
      display: inline;
    }
  `}
`;

export const Action = styled.div`
  margin: ${`${2 * spacer}rem 0`};
  min-width: 88%;
`;
