import { PrismicRichText } from '@prismicio/react';
import { ReactElement } from 'react';

import Link from 'next-translate-routes/link';

import { useMediaQuery } from '@hooks/useMediaQuery';
import { smallScreen } from '@styles/constants';

import { HomepageBodyCtaFragment } from '@prismic/gqlTypes/prismicOperations';
import { linkResolver } from '@prismic/prismic-config';
import { PrismicNextImage } from '@prismic/components/PrismicNextImage';

import { Button } from '@components/atoms/Button';

import * as S from './styles';

type Slice = HomepageBodyCtaFragment;
type SlicePrimary = Slice['primary'];

interface IProps {
  // type: 'cta';
  // primary: SlicePrimary;
  slice: Slice | null;
}

const renderButton = (primary: SlicePrimary): ReactElement | null => {
  if (primary.button_link && 'url' in primary.button_link) {
    // check if url contains domain
    // const reg = new RegExp('^(?:[a-z]+:)?//', 'i');
    const reg = '/^(?:[a-z]+:)?///i';
    return reg.match(primary.button_link.url) ? (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a href={primary.button_link.url} target="_blank">
        <Button size="lg">
          <PrismicRichText field={primary?.button_label} />
        </Button>
      </a>
    ) : (
      <Link href={linkResolver(primary.button_link)}>
        <a className="home-page-hero__button">
          <Button size="lg">
            <PrismicRichText field={primary?.button_label} />
          </Button>
        </a>
      </Link>
    );
  }
  return null;
};

export function SliceCta({ slice: { primary } }: IProps): ReactElement {
  const videoId = primary?.video?.video_id;
  const image = primary?.image;
  const label = 'center';
  const [isSmallScreen] = useMediaQuery(`(max-width: ${smallScreen}px)`);

  return (
    <S.Wrapper className="slice-cta">
      <S.Video className="media-wrapper">
        {isSmallScreen && videoId && (
          <iframe
            src={`https://player.vimeo.com/video/${videoId}?background=1&controls=0`}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            title="video"
          />
        )}
        {isSmallScreen && !videoId && image && (
          <PrismicNextImage
            field={image}
            sizes="100vw"
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        )}
        {!isSmallScreen && image && (
          <PrismicNextImage
            field={image}
            sizes="100vw"
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        )}
      </S.Video>
      <S.Content className="content-wrapper" justify={label}>
        <S.ContentInner>
          <S.ContentTitle>
            {primary.title && <PrismicRichText field={primary.title} />}
          </S.ContentTitle>
          <S.ContentText>
            {primary.text && <PrismicRichText field={primary.text} />}
          </S.ContentText>
          {renderButton(primary)}
        </S.ContentInner>
      </S.Content>
    </S.Wrapper>
  );
}

SliceCta.componentName = 'cta';
