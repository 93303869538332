import { ReactElement, useRef } from 'react';

import { useIntl } from 'react-intl';

import Slider, { Settings } from 'react-slick';

import Link from 'next-translate-routes/link';

import { PrismicRichText } from '@prismicio/react';

import { linkResolver } from '@prismic/prismic-config';

import { PostsTile } from '@components/molecules/PostsTile';

import { mediumScreen, smallScreen } from '@styles/constants';

import { commonMessages } from '../../../../intl';

import { IProps } from './types';
import * as S from './styles';

const sliderSettings: Settings = {
  arrows: true,
  autoplay: false,
  dots: false,
  infinite: false,
  // centerMode: true,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: mediumScreen,
      settings: {
        slidesToShow: 2,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: smallScreen,
      settings: {
        slidesToShow: 1,
        swipeToSlide: true,
      },
    },
  ],
};

export function SliceCollections({
  slice: { fields, primary },
}: IProps): ReactElement {
  const slider = useRef(null);
  const intl = useIntl();

  return (
    <S.Wrapper className="slice-collections">
      <S.Container>
        <S.Header>
          {primary?.title && (
            <S.Title>
              <PrismicRichText field={primary.title} />
            </S.Title>
          )}
          {primary?.link && (
            <Link href={linkResolver(primary.link)} passHref>
              <S.Link>{intl.formatMessage(commonMessages.showMore)}</S.Link>
            </Link>
          )}
        </S.Header>
        {fields && (
          <Slider {...sliderSettings} ref={slider}>
            {fields.map((item, i) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              if (!item) {
                return null;
              }

              return (
                <S.Slide>
                  <PostsTile
                    key={i}
                    link={linkResolver(item?.link)}
                    image={item?.image}
                    title={item?.title}
                    text={item?.text}
                    align="left"
                    square
                  />
                </S.Slide>
              );
            })}
          </Slider>
        )}
      </S.Container>
    </S.Wrapper>
  );
}

SliceCollections.componentName = 'collections';
