import { PrismicRichText } from '@prismicio/react';
import { ReactElement } from 'react';

import { useMediaQuery } from '@hooks/useMediaQuery';
import { smallScreen } from '@styles/constants';

import { PrismicNextImage } from '@prismic/components/PrismicNextImage';
import { Button } from '@components/atoms/Button';
import { linkResolver } from '@prismic/prismic-config';

import { IProps } from './types';
import * as S from './styles';

export function HomePageHero({ hero }: IProps): ReactElement {
  const [isSmallScreen] = useMediaQuery(`(max-width: ${smallScreen}px)`);

  const { image, banner_title, text, button_label, button_link } = hero[0];

  return (
    <S.Wrapper className="home-page-hero">
      <S.Image className="home-page-hero__image-wr">
        <PrismicNextImage field={image} width={1920} height={960} priority />
      </S.Image>
      <S.Content className="home-page-hero__content">
        <S.Title className="home-page-hero__title-wrapper">
          <S.TitleInner className="home-page-hero__title-inner">
            <S.TitleBefore className="home-page-hero__title-before" />
            <S.TitleContent className="home-page-hero__title-content">
              {banner_title && <PrismicRichText field={banner_title} />}
            </S.TitleContent>
            <S.TitleAfter className="home-page-hero__title-after" />
          </S.TitleInner>
        </S.Title>
        {!isSmallScreen && (
          <S.Text className="home-page-hero__text">
            <PrismicRichText field={text} />
          </S.Text>
        )}
        <S.Button
          className="home-page-hero__button"
          href={linkResolver(button_link)}
        >
          <Button size="lg" color="primary">
            {button_label}
          </Button>
        </S.Button>
      </S.Content>
    </S.Wrapper>
  );
}
