import * as React from 'react';

import { PrismicHomePage } from '@pages/HomePage';
import { PrismicHomePageQuery } from '@prismic/gqlTypes/prismicOperations';

import { GetShopQuery } from '@sdk/gqlTypes/generatedOperations';

const Page: React.FC<{
  shop: GetShopQuery['shop'];
  homepage: PrismicHomePageQuery['homepage'];
}> = ({ shop, homepage }) => <PrismicHomePage homepage={homepage} />;

export default Page;
