import { PrismicRichText } from '@prismicio/react';

import { ReactElement } from 'react';

import { PrismicNextImage } from '@prismic/components/PrismicNextImage';

import { useIntl } from 'react-intl';

import { IProps } from './types';
import * as S from './styles';

import { commonMessages } from '../../../../intl';

export function SliceBenefits({ slice: { fields } }: IProps): ReactElement {
  const intl = useIntl();
  return (
    <S.Wrapper className="benefits">
      <S.Container>
        <S.Header className="benefits__header">
          <S.Title className="benefits__title">
            {intl.formatMessage(commonMessages.SeeAndFeelTheDifference)}
          </S.Title>
        </S.Header>
        {fields && (
          <S.List className="benefits__list">
            {fields.map((item, i) => (
              <S.Item key={i} className="benefits__item">
                <S.ItemCount className="benefits__item-count">
                  0{i + 1}
                </S.ItemCount>
                {/* TODO: add icon */}
                <S.IconWrapper className="benefits__icon-wr">
                  <S.IconShade className="benefits__icon-shade">
                    <div>
                      <PrismicNextImage field={item.img_shade} />
                    </div>
                  </S.IconShade>
                  <div className="benefits__icon">
                    <div>
                      <PrismicNextImage field={item.img_default} />
                    </div>
                  </div>
                  <S.IconHover className="benefits__icon-hover">
                    <div>
                      <PrismicNextImage field={item.img_hover} />
                    </div>
                  </S.IconHover>
                </S.IconWrapper>
                <S.ItemTitle className="benefits__item-title">
                  <S.ItemCount className="benefits__item-count mobile-only">
                    0{i + 1}
                  </S.ItemCount>
                  {item.title && <PrismicRichText field={item.title} />}
                </S.ItemTitle>
                <S.ItemContent className="benefits__item-content">
                  <S.ItemHint className="benefits__item-text benefits__item-hint">
                    {item.text && <PrismicRichText field={item.text} />}
                  </S.ItemHint>
                  <S.ItemDescription className="benefits__item-text benefits__item-description">
                    {item.more_text && (
                      <PrismicRichText field={item.more_text} />
                    )}
                  </S.ItemDescription>
                </S.ItemContent>
                <S.ItemMore className="benefits__item-more">
                  {intl.formatMessage(commonMessages.more)}
                </S.ItemMore>
              </S.Item>
            ))}
          </S.List>
        )}
      </S.Container>
    </S.Wrapper>
  );
}

SliceBenefits.componentName = 'benefits';
