import * as React from 'react';
import { NextPage } from 'next';
import { NextSeo, NextSeoProps } from 'next-seo';
import Head from 'next/head';

import { GetShopQuery } from '@sdk/gqlTypes/generatedOperations';
import { PrismicHomePageQuery } from '@prismic/gqlTypes/prismicOperations';

import { structuredData } from '@temp/core/SEO/Homepage/structuredData';

import Page from './Page';

export interface ViewProps {
  shop: GetShopQuery['shop'];
  homepage: PrismicHomePageQuery['homepage'];
  seo: NextSeoProps | null;
}

const View: NextPage<ViewProps> = ({ shop, homepage, seo }) => (
  <div className="home-page">
    <NextSeo {...seo} />
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: structuredData(shop) }}
        key="homepage-jsonld"
      />
    </Head>
    <Page shop={shop} homepage={homepage} />
  </div>
);

export default View;
