import { ReactElement } from 'react';

import { useRouter } from 'next/router';

import { localeToSaleorQuery } from '@utils/regions';
import { Loader } from '@components/atoms/Loader';
import { NotFound } from '@pages/NotFound';

import { useReviewsQuery } from '@sdk/gqlTypes/generatedHooks';

import { ProductReviews } from '.';

export function ProductReviewsWithQuery(): ReactElement {
  const { locale } = useRouter();
  const { data, loading, error } = useReviewsQuery({
    variables: {
      hasImages: true,
      ...localeToSaleorQuery(locale),
      pageSize: 5,
    },
  });

  if (error) {
    return <p>Error : {error.message}</p>;
  }
  if (loading) {
    return <Loader />;
  }
  if (data && data.reviews === null) {
    return <NotFound />;
  }
  return (
    <ProductReviews reviews={data.reviews.edges.map(({ node }) => node)} />
  );
}
