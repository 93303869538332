/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as prismicR from '@prismicio/richtext';

import Link from 'next-translate-routes/link';

import { PrismicNextImage } from '@prismic/components/PrismicNextImage';

import { IProps } from './types';
import * as S from './styles';

export const PostsTile: React.FC<IProps> = ({
  key,
  link,
  image,
  title,
  text,
  tags,
  align = 'center',
  square = true,
}) => (
  <Link href={link} key={key}>
    <a>
      <S.Wrapper>
        <S.Image square={square}>
          <PrismicNextImage
            field={image}
            height={320}
            sizes="(max-width: 540px) 66vw, (max-width: 1279px) 50vw, 20vw"
          />
        </S.Image>
        <S.Content align={align}>
          {title && typeof title !== 'string' && (
            <S.Title>{prismicR.asText(title)}</S.Title>
          )}
          {title && typeof title === 'string' && <S.Title>{title}</S.Title>}
          {text && <S.Text>{prismicR.asText(text)}</S.Text>}
          {tags && <S.Tags>{tags.join(', ')}</S.Tags>}
        </S.Content>
      </S.Wrapper>
    </a>
  </Link>
);
