import { ReactElement } from 'react';

import { ProductReviewsWithQuery } from '@components/organisms/ProductReviews/ProductReviewsWithQuery';

import * as S from './styles';

export function SliceReviews(): ReactElement {
  return (
    <S.Wrapper className="slice-reviews">
      <ProductReviewsWithQuery />
    </S.Wrapper>
  );
}

SliceReviews.componentName = 'reviews';
