import { SliceBenefits } from '@components/slices/SliceBenefits';
import { SliceCollections } from '@components/slices/SliceCollections';
import { SliceCta } from '@components/slices/SliceCta';
import { SliceImageCta } from '@components/slices/SliceImageCta';
import { SliceImageCards } from '@components/slices/SliceImageCards';
import { SliceInspiration } from '@components/slices/SliceInspiration';
import { SliceInstagramPosts } from '@components/slices/SliceInstagramPosts';
import { SliceReviews } from '@components/slices/SliceReviews';
import { SliceTrusted } from '@components/slices/SliceTrusted';
import { SliceLinkButton } from '@components/slices/SliceLinkButton';

export const components = {
  // accordion: SliceAccordion,
  // architectreviews: SliceArchitectReviews,
  benefits: SliceBenefits,
  // benefitsprogram: SliceBenefitsProgram,
  collections: SliceCollections,
  // color_sample: SliceColorSample,
  // consulttoolbox: SliceConsultToolbox,
  // content_photo_photo_50: SliceBlogContentPhotoPhoto,
  // content_photo_text_30_60: SliceBlogContentTextPhoto,
  // content_text_photo_60_30: SliceBlogContentTextPhoto,
  // content_text_text_50: SliceBlogContentTextText,
  cta: SliceCta,
  imagecta: SliceImageCta,
  imagecard: SliceImageCards,
  // downloadfiles: SliceDownloadFiles,
  // excerpt: SliceExerpt,
  // formembded: SliceFormEmbded,
  // franchisecontact: SliceFranchiseContact,
  // heroimg: SliceHeroImg,
  // iconbanner: SliceIconBanner,
  // iconcta: SliceIconCta,
  inspiration: SliceInspiration,
  instagram_posts: SliceInstagramPosts,
  // labeled_icons: SliceLabeledIcons,
  linkbutton: SliceLinkButton,
  // mediaslider: SliceMediaSlider,
  // ourpartners: SliceOurPartners,
  // product_details: SliceProductDetails,
  // product_features: SliceProductFeatures,
  // quote: SliceQuote,
  // relatedpagesslider: SliceRelatedPagesSlider,
  reviews: SliceReviews,
  // richtext: SliceRichText,
  // showroom: SliceShowRoom,
  // tagimage: SliceTagImage,
  trusted: SliceTrusted,
};
