import { media, styled } from '@styles';

export const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  ${media.smallScreen`
    min-height: initial;
  `}
`;

export const Video = styled.div`
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
  iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  img {
    height: 100%;
  }
`;

export const Content = styled.div<{ justify: string }>`
  text-align: center;
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: ${({ justify }) => justify};
  height: 100%;
  width: 100%;
  ${media.mediumScreen`
    padding: 110px 0;
  `}
  ${media.smallScreen`
    padding: 50px 0;
  `}
  &:before {
    content: '';
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      #e9dada 0%,
      rgba(255, 255, 255, 0) 100%
    );
    opacity: 0.7;
  }
`;

export const ContentInner = styled.div`
  max-width: 790px;
  // margin: 0 auto;
  ${media.largeScreen`
    max-width: 550px;
  `}
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContentTitle = styled.div`
  font-family: ${(props) => props.theme.typography.thinFontFamily};
  font-size: ${(props) => props.theme.typography.h1FontSize};
  line-height: 1em;
  margin-bottom: 20px;
  ${media.smallScreen`
    padding: 0 25px;
  `}
  * {
    font: inherit;
  }
`;

export const ContentText = styled.div`
  font-family: ${(props) => props.theme.typography.extraFontFamily};
  font-size: ${(props) => props.theme.typography.baseFontSize};
  max-width: 593px;
  margin: 0 auto 60px;
  line-height: 1.6em;
  opacity: 0.8;
  ${media.largeScreen`
    max-width: 455px;
    margin: 0 auto 40px;
  `}
  ${media.smallScreen`
    margin-bottom: 30px;
    padding: 0 25px;
  `}
  * {
    font: inherit;
  }
`;
