import { styled } from '@styles';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.redLight};
  text-align: center;
  transition: 0.3s;
  min-height: 100%;
  padding: 1rem;
  &:hover {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
    // background-color: ${(props) => props.theme.colors.white};
  }
`;

export const Image = styled.div<{ square: boolean }>`
  width: 100%;
  position: relative;
  overflow: hidden;
  position: relative;
  img {
    object-fit: cover;
    width: 100%;
    height: ${({ square }) => (square ? '100%' : undefined)};
  }
`;

export const Content = styled.div<{ align: string }>`
  padding: 1rem 0;
  text-align: ${({ align }) => align};
`;

export const Title = styled.h3`
  font-weight: 300;
  font-family: ${(props) => props.theme.typography.extraFontFamily};
  font-size: ${(props) => props.theme.typography.smallFontSize};
  line-height: ${(props) => props.theme.typography.baseFontSize};
  color: ${(props) => props.theme.colors.red};
  text-transform: capitalize;
`;

export const Text = styled.p`
  font-family: Inter;
  font-size: ${(props) => props.theme.typography.labelFontSize};
  line-height: ${(props) => props.theme.typography.smallFontSize};
  color: ${(props) => props.theme.colors.baseFont};
  margin-top: 7px;
`;

export const Tags = styled.p`
  font-family: Inter;
  font-size: ${(props) => props.theme.typography.labelFontSize};
  line-height: ${(props) => props.theme.typography.smallFontSize};
  color: ${(props) => props.theme.colors.graySemi};
  text-transform: capitalize;
  margin-top: 7px;
`;
