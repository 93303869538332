import * as React from 'react';
import { useIntl } from 'react-intl';

import Link from 'next-translate-routes/link';
import { paths } from '@paths';
import { Button } from '@components/atoms/Button';

import * as S from './styles';

interface NotFoundProps {
  message?: string;
}

export const NotFound: React.FC<NotFoundProps> = () => {
  const intl = useIntl();
  return (
    <S.Page>
      <S.Header>404</S.Header>
      <S.Ruler />
      <S.Message>
        <p>
          {intl.formatMessage({
            defaultMessage: 'We can’t seem to find a page you are looking for!',
            id: 'A8OWpi',
          })}
        </p>
        <p>
          {intl.formatMessage({
            defaultMessage:
              'You may have mistyped the address or the page may have moved.',
            id: '+qoOl7',
          })}
        </p>
        <p>
          {intl.formatMessage({
            defaultMessage:
              'We’re sorry for the error and hope you’ll have a good day.',
            id: '5wk80E',
          })}
        </p>
      </S.Message>
      <S.Action>
        <Link href={paths.home}>
          <a>
            <Button color="secondary" size="lg">
              {intl.formatMessage({
                defaultMessage: 'Back to home',
                id: 'aIRWQB',
              })}
            </Button>
          </a>
        </Link>
      </S.Action>
    </S.Page>
  );
};
